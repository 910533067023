import React from "react"
import HeadingBG from "@images/pexels-rasik-1416899.jpg"

const PageHeading = ({ bg, children }) => (
	<div
		className="page-heading"
		style={{ backgroundImage: `url(${bg ? bg : HeadingBG} )` }}
	>
		{children}
	</div>
)

export default PageHeading
