import React from "react"
import Layout from "@components/layout"
import { graphql, Link } from "gatsby"
import SeoComponent from "@components/seo"
import PageHeading from "@components/pageHeading"
import PlaceholderBG from "@images/no-image-placeholder.jpg"

const Blogs = ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <SeoComponent
        title="Latest Posts"
        description={data.site.siteMetadata.description}
      />
      <PageHeading>
        <div className="container">
          <div className="section-heading text-white text-center">
            <span>Blog</span>
            <h1>Latest Posts</h1>
          </div>
        </div>
      </PageHeading>
      <div className="py-10">
        <div className="container">
          <div className="row">
            {data.allSanityPost.edges.map((blog) => (
              <div className="col-md-6 col-lg-4 mb-5">
                <div className="blog-item-wrapper h-100">
                  <Link
                    to={`/` + blog.node.slug.current}
                    aria-label={blog.node.title}
                  >
                    <div className="blog-img rounded">
                      <div
                        className="image-bg"
                        style={{
                          backgroundImage: `url(${
                            blog.node.mainImage &&
                            blog.node.mainImage.asset &&
                            blog.node.mainImage.asset.url
                              ? blog.node.mainImage.asset.url
                              : PlaceholderBG
                          })`,
                        }}
                      ></div>
                    </div>
                  </Link>
                  <span className="d-block text-uppercase mt-3 small text-muted">
                    {blog.node.publishedAt}
                  </span>
                  <Link
                    className="link-unstyled"
                    to={`/` + blog.node.slug.current}
                  >
                    <h2 className="h4 mb-2 font-weight-bold link-unstyled">
                      {blog.node.title}
                    </h2>
                  </Link>
                  <p>{blog.node.excerpt}</p>
                  <Link
                    className="btn btn-secondary"
                    to={`/` + blog.node.slug.current}
                  >
                    Read More
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <span className="d-block text-muted text-center font-italic">
            You've reached the end of blog posts.
          </span>
        </div>
      </div>
    </Layout>
  )
}

export default Blogs

export const blogQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityPost {
      edges {
        node {
          id
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              url
            }
          }
          excerpt
          slug {
            current
          }
          categories {
            title
          }
        }
      }
      group(field: categories___title) {
        fieldValue
      }
    }
  }
`
